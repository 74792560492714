import { Controller } from '@hotwired/stimulus'
import Splide from '@splidejs/splide'

export default class extends Controller {
  connect() {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    let perPage = 4
    if (vw <= 400) {
      perPage = 1
    } else if (vw <= 850) {
      perPage = 2
    }
    this.splide = new Splide('.splide.slideshow', {
      speed: 1000,
      perPage: perPage,
      rewind: true
    })
    this.splide.mount()
  }
}
