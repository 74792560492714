import { Controller } from '@hotwired/stimulus'

const config = {}
const observer = new IntersectionObserver((entries) => {
  for (let entry of entries) {
    if (entry.isIntersecting) {
      var t = entry.target
      var animation = t.dataset.animation
      if (animation) {
        t.className = `${t.className} ${animation}`
      }
      observer.unobserve(t)
    }
  }
}, config)


export default class extends Controller {
  static targets = ['animateable']

  connect() {
    observer.observe(this.animateableTarget)
  }
}
