import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['section']

  connect() {
    var mainHeader = document.querySelector('header.main-header')
    var scrollHeader = document.querySelector('header.scroll-header')
    this.intro = document.querySelector('.intro')
    var options = {
      rootMargin: '0px',
      threshold: 0.1
    }
    this.showScrollObserver = new IntersectionObserver((entries) => {
      for (let entry of entries) {
        setTimeout(() => {
          if (entry.isIntersecting) {
            console.log('show scroll header')
            mainHeader.classList.add('shrunk')
            scrollHeader.classList.add('expanded')
          }
        }, 200)
      }
    }, options)
    this.sectionTargets.forEach((target, index) => {
      if (index === 0) { return }
      this.showScrollObserver.observe(target)
    })

    this.showMainObserver = new IntersectionObserver((entries) => {
      for (let entry of entries) {
        setTimeout(() => {
          if (entry.isIntersecting) {
            mainHeader.classList.remove('shrunk')
            scrollHeader.classList.remove('expanded')
          }
        }, 200)
      }
    }, options)
    this.showMainObserver.observe(this.intro)
  }

  disconnect() {
    this.sectionTargets.forEach((target, index) => {
      if (index === 0) { return }
      this.showScrollObserver.unobserve(target)
    })
    this.showScrollObserver.disconnect()

    this.showMainObserver.unobserve(this.intro)
    this.showMainObserver.disconnect()
  }
}
