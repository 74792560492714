import { Controller } from '@hotwired/stimulus'
import Splide from '@splidejs/splide'

export default class extends Controller {
  static targets = []

  connect() {
    console.log('intro controller connected')
    this.splide = new Splide('.intro-splide', {
      speed: 1000,
      type: 'loop',
      perPage: 1,
      autoplay: true,
      interval: 5000
    })
    this.splide.mount()
  }
}
