import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    selector: String,
    event: String
  }

  connect() {
    console.log(`selector: ${this.selectorValue} -- event: ${this.eventValue}`)
  }

  fire(e) {
    const event = new CustomEvent(this.eventValue, {
      detail: {
        selector: this.selectorValue,
        originalEvent: e
      }
    })
    const listener = document.querySelector(this.selectorValue)
    if (listener) {
      listener.dispatchEvent(event)
    }
  }
}
