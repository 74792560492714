import { Controller } from '@hotwired/stimulus'
import Splide from '@splidejs/splide'
import { URLHash } from '@splidejs/splide-extension-url-hash'

export default class extends Controller {
  static targets = []

  static values = {
    id: Number
  }

  connect() {
    console.log(`gallery controller connected -- id = ${this.idValue}`)
  }

  disconnect() {
    if (this.splide) {
      this.splide.destroy()
    }
  }

  showImage() {
    const container = this.galleryContainer()
    this.buildSplide()
    container.classList.remove('hide')
    container.classList.add('show')
  }

  close() {
    const container = this.galleryContainer()
    container.classList.remove('show')
    container.classList.add('hide')
    this.splide.destroy()
  }

  get containerSelector() {
    return `.portfolio-gallery-lightbox[data-id="${this.idValue}"]`
  }

  galleryContainer() {
    return document.querySelector(this.containerSelector)
  }

  buildSplide() {
    this.splide = new Splide(
      `${this.containerSelector} .splide`,
      {
        height: '100vh',
        width: '100vw'
      }
    )
    this.splide.mount({ URLHash })
  }
}
