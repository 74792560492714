import { application } from '../application'

// import FormSectionController from './form_section_controller'
// application.register('form-section', FormSectionController)

import IntroController from './intro_controller'
application.register('intro', IntroController)

import MenuIntersectionController from './menu_intersection_controller'
application.register('menu-intersection', MenuIntersectionController)

import SlideshowController from './slideshow_controller'
application.register('slideshow', SlideshowController)

import AnimationController from './animation_controller'
application.register('animation', AnimationController)

import TempController from '../temp_controller'
application.register('temp', TempController)

import GalleryController from './gallery_controller'
application.register('gallery', GalleryController)

import RedispatchController from '../redispatch_controller'
application.register('redispatch', RedispatchController)

import DropdownController from './dropdown_controller'
application.register('dropdown', DropdownController)

import MobileMenuController from './mobile_menu_controller'
application.register('mobile-menu', MobileMenuController)
