import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.element.style.opacity = 1
    this.element.style.transition = 'opacity 1s'
    setTimeout(() => {
      this.element.style.opacity = 0
    }, 5000)
    setTimeout(() => {
      this.element.style.display = 'none'
    }, 6000)
    setTimeout(() => {
      this.element.remove()
    }, 8000)
  }
}
