import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['menu']

  close() {
    this.menuTarget.classList.remove('show')
  }

  show() {
    this.menuTarget.classList.add('show')
  }
}
